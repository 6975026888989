@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit&family=Saira:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  width: 100vw;
  margin-left: 0vw;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  padding: 0;
  font-family: "segoe ui";
  overflow-x: hidden;
}

.nav {
  height: 12vh;
  width: 100%;
  background-color: #fff !important;
  position: fixed;
  z-index: 7;
  padding-top: 1.5vh;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.nav > .nav-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 50vw;
  position: relative;
}

.nav > .nav-header > .nav-title {
  display: inline-block;
  font-size: 22px;
  padding: 10px 10px;
  transform: translate(60px, 0);
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {
  display: inline;
  float: right;
  font-size: 19px;
  font-weight: 600;
  font-family: "Merriweather", "sans-serif";

  margin: 0 10px;
}

.nav > .nav-links > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: black;
}

.nav > .nav-links > a:hover {
  text-decoration: underline;
}

.nav > #nav-check {
  display: none;
}

.logo {
  cursor: pointer;
  position: absolute;
  height: 30vh;
  top: -10vh;
}

@media (max-width: 800px) {
  /* .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .nav > .nav-btn > label:hover,
  .nav #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid rgb(10, 10, 10);
  }
  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #333;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
  }
  .nav > .nav-links > a {
    display: block;
    width: 100%;
  }
  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }
  .nav > #nav-check:checked ~ .nav-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
  } */
  .nav-title,
  .nav-links {
    visibility: hidden;
  }
  .logo {
    left: 20vw;
  }
}
@media (max-height: 420px) {
  .logo {
    left: 20vw;
  }
}
@media (max-height: 380px) {
  .logo {
    left: 10vw;
  }
}
/* @media only screen and (max-width: 450px) and (min-width: 400px) {
  .nav {
    height: 8vh;
  }
  .logo {
    margin-left: 25vw;
  }
}
@media (max-width: 380px) {
  .nav {
    height: 9vh;
  }
  .logo {
    min-height: 80px;
    margin-top: -3vh !important;
    margin-left: 22vw;
  } */
/* .logo {
    margin-top: 5vh !important;
  } */

.section {
  width: 100vw;
  display: flex;
  margin-top: 5vh;
}

/* TOP SECTION */
.main {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: flex;
  background-image: url("https://static.vecteezy.com/system/resources/previews/000/621/296/non_2x/abstract-elegant-gray-and-white-squares-pattern-background-texture-vector.jpg");
  background-size: cover;
}

.main-left {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 10px;
  margin-left: 1vw;
  padding-right: 9vw;
}

.l-1,
.l-2 {
  font-size: 42px;
  font-weight: bold;
  text-align: left;
  font-family: "Merriweather", "sans-serif";
}

.l-2 {
  color: #ff4c4c;
}

.l-3 {
  font-size: 35px;
  font-weight: bold;
  padding-top: 5vh;
  font-family: "Kanit", sans-serif;
  color: #ff4c4c;
}

.main-right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.top-img {
  height: 65%;
  width: 95%;
  object-fit: cover;
}

/* CLIENTS SECTION */
.clients {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.head {
  font-size: 2.5em;
  color: #ff4c4c;
  font-family: "Saira", "cursive";
  font-weight: bold;
  text-align: center;
  width: 100vw;
}

.logo-scroll {
  width: 100vw;
  display: flex;
  padding: 20px;
  overflow: hidden;
  white-space: nowrap;
}

.about-us {
  width: 100vw;
  height: auto;
  padding: 20px;
  display: flex;
  margin-top: 10vh;
}
.abt-r {
  width: 55%;
}

.abt-l {
  width: 45%;
  padding-left: 6vw;
  /* background-color: whitesmoke; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2vw;
  /* border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
}

.abt-1 {
  color: #ff4c4c;
  font-weight: bold;
  font-size: 2rem;
}

.abt-2 {
  font-size: 20px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
}
.second-img {
  border-radius: 5px;
}
.services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  background-color: whitesmoke;
}
.service-tab {
  display: flex;
  flex-direction: row;
  margin: 0 4vw;
  align-items: center;
}
.service-img {
  height: 40vh;
  width: 35vw;
  border-radius: 5px;
}
.service-text {
  width: 25vw;
  margin: 0 2vw;
  font-size: 20px;
}

.signup {
  margin: 15vh;
  display: flex;
  justify-content: center;
}
@keyframes autoscroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.logos {
  display: flex;
  position: relative;
  animation: autoscroll 10s linear infinite;
  justify-content: space-around;
  z-index: -1;
}
.scroll-item {
  height: 100px;
  width: 150px;
  margin: 0 5px;
  background-color: blueviolet;
}

/* SERVICES SECTION */
@media (max-width: 1300px) {
  .top-img {
    height: 50%;
    min-width: 45%;
  }
}

@media (max-width: 1050px) {
  .main {
    margin-top: 9vh;
    height: 100vh;
    display: flex;
    align-items: center;
  }
  .main-left {
    width: 55%;
  }
  .main-right {
    width: 45%;
  }
  .l-1 {
    display: flex;
    flex-wrap: wrap;
    font-size: 32px;
  }
  .top-img {
    height: 65%;
  }
  .service-tab {
    margin-top: -10vh;
  }
  .signup {
    margin-top: -2vh;
  }
}
@media (max-width: 900px) {
  .l-1 {
    font-size: 28px;
  }
  .top-img {
    height: 80%;
  }
  .service-text {
    font-size: 24px;
  }
}
@media (max-width: 600px) {
  .main {
    flex-direction: column-reverse;
    height: 90vh;
    margin-top: -50vh;
    background-size: cover;
  }
  .l-1 {
    text-align: center;
  }
  .top-img {
    height: 100%;
    width: 100%;
  }
  .main-left,
  .main-right {
    width: 100%;
    height: auto;
  }

  .l-1,
  .l-2 {
    font-size: 25px;
  }

  .head {
    font-size: 1.8rem;
  }
  .about-us {
    flex-direction: column;
  }
  .services {
    box-shadow: none;
  }
  .service-tab {
    margin-top: -5vw;
    margin-left: -3vw;
    flex-direction: column;
  }
  .service-text {
    width: 80vw;
    font-size: 18px;
    margin-top: 10vw;
  }

  .service-img {
    height: 40vh;
    width: 90vw;
    margin-top: 5vw;
  }
  /* .name {
    visibility: hidden;
  } */
}
@media only screen and (max-width: 450px) and (min-width: 400px) {
  .main {
    min-height: 100vh;
    margin-top: -10vh;
  }
  .top-img {
    height: 100%;
  }
}
@media (max-width: 400px) {
  .clients {
    margin-top: -4vh;
    border-top: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
  .main {
    margin-top: 2vh;
    min-height: 100vh;
  }
  .top-img {
    height: 30vh;
    width: 80vw;
  }
  .main-left {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .l-1 {
    text-align: center;
    font-size: 20px !important;
    font-size: 15px;
    margin-top: 10vw;
  }
  .l-3 {
    margin-top: -5vh;
    font-size: 20px;
    text-align: center !important;
  }
  .head {
    font-size: 20px;
  }
  .abt-l {
    margin-left: -2vw;
  }
  .abt-2 {
    font-size: 15px;
  }
  .tech-stack {
    margin-top: 35vh;
  }
}

@media (max-width: 380px) {
  .main {
    margin-top: -15vh;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.logo-slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  margin: auto;
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100vw;
}
.logo-slider::before,
.logo-slider::after {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 10px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.logo-slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.logo-slider::before {
  left: 0;
  top: 0;
}
.logo-slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.logo-slider .slide {
  height: 100px;
  width: 250px;
  margin: 20px;
}
.slide img {
  object-fit: contain;
}

.tech-stack {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tech-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5vw 2vw 5vw 1vw;
}

.single-tech {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 15vh;
  width: 8vw;
  margin: 4vh 3vw;
}

.tech-img {
  height: 80%;
  width: 90%;
  object-fit: contain;
}

.name {
  font-family: sans-serif;
}

@media (max-width: 600px) {
  .tech-stack {
    margin-top: 35vh;
  }
  .tech-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .single-tech {
    width: 20vw;
    height: 15vh;
  }
}

.form-container {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: 5px;
  padding: 10vh 5vw;
  width: 40vw;
  background-image: url(/static/media/contact-form-bg.3726dbc3.jpg);
  background-size: cover;
}

form {
  width: 100%;
}
.field {
  border: solid 2px black;
  border-radius: 2px;
  background-color: #fff;
}

input[type="text"],
input[type="email"] {
  width: 80%;
  background-color: #fff;
  padding: 12px 20px;
  margin: 8px 0;
  border-radius: 5px !important;
  box-sizing: border-box;
  border: 1.5px solid #555 !important;
  background-color: white !important;
  transition: 0.5s;
  outline: none;
}

input[type="text"]:focus,
input[type="email"]:focus {
  border-radius: 5px;
}
textarea {
  border: solid 1.5px black;
  width: 80%;
  margin-left: 1px;
  border-radius: 5px;
  outline: none;
  background-color: #fff;
  height: 15vh;
  width: 100%;
}

.welcome {
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 5vh;
}

.ghost-round {
  cursor: pointer;
  background: rgba(255, 76, 76, 0.75);
  border: 1px solid rgba(255, 76, 76, 0.75);
  padding: 0 3vw;
  border-radius: 25px;
  align-self: flex-end;
  font-size: 19px;
  font-size: 1.3rem;
  font-family: roboto;
  font-weight: 300;
  line-height: 2.5em;
  color: white;
  font-weight: bold;
  transition: all 0.2s ease;
  margin-top: 5vh;
  margin-bottom: -2vh;
}

.send-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 1050px) {
  .form-container {
    min-width: 55vw;
    margin-top: 5vh;
    padding: 6vh 5vw;
  }
}

@media (max-width: 600px) {
  .form-container {
    min-width: 85vw;
  }
}

ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  background: #151414;
  position: relative;
  width: 100vw;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.row {
  display: flex;
  justify-content: center;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  margin: 0 15px;
  display: inline-block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.copyright-area {
  background: #202020;
  padding: 25px 0;
  display: flex;
  justify-content: center;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
  text-align: center;
}
.copyright-text p a {
  color: #ff5e14;
}

@media (max-width: 600px) {
  .row {
    flex-direction: column;
  }
}

