@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap");

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  padding: 0;
  font-family: "segoe ui";
  overflow-x: hidden;
}

.nav {
  height: 12vh;
  width: 100%;
  background-color: #fff !important;
  position: fixed;
  z-index: 7;
  padding-top: 1.5vh;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.nav > .nav-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 50vw;
  position: relative;
}

.nav > .nav-header > .nav-title {
  display: inline-block;
  font-size: 22px;
  padding: 10px 10px;
  transform: translate(60px, 0);
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {
  display: inline;
  float: right;
  font-size: 19px;
  font-weight: 600;
  font-family: "Merriweather", "sans-serif";

  margin: 0 10px;
}

.nav > .nav-links > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: black;
}

.nav > .nav-links > a:hover {
  text-decoration: underline;
}

.nav > #nav-check {
  display: none;
}

.logo {
  cursor: pointer;
  position: absolute;
  height: 30vh;
  top: -10vh;
}

@media (max-width: 800px) {
  /* .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .nav > .nav-btn > label:hover,
  .nav #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid rgb(10, 10, 10);
  }
  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #333;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
  }
  .nav > .nav-links > a {
    display: block;
    width: 100%;
  }
  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }
  .nav > #nav-check:checked ~ .nav-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
  } */
  .nav-title,
  .nav-links {
    visibility: hidden;
  }
  .logo {
    left: 20vw;
  }
}
@media (max-height: 420px) {
  .logo {
    left: 20vw;
  }
}
@media (max-height: 380px) {
  .logo {
    left: 10vw;
  }
}
/* @media only screen and (max-width: 450px) and (min-width: 400px) {
  .nav {
    height: 8vh;
  }
  .logo {
    margin-left: 25vw;
  }
}
@media (max-width: 380px) {
  .nav {
    height: 9vh;
  }
  .logo {
    min-height: 80px;
    margin-top: -3vh !important;
    margin-left: 22vw;
  } */
/* .logo {
    margin-top: 5vh !important;
  } */
