@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");

ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  background: #151414;
  position: relative;
  width: 100vw;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.row {
  display: flex;
  justify-content: center;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  margin: 0 15px;
  display: inline-block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.copyright-area {
  background: #202020;
  padding: 25px 0;
  display: flex;
  justify-content: center;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
  text-align: center;
}
.copyright-text p a {
  color: #ff5e14;
}

@media (max-width: 600px) {
  .row {
    flex-direction: column;
  }
}
