@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.logo-slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  margin: auto;
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100vw;
}
.logo-slider::before,
.logo-slider::after {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 10px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.logo-slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.logo-slider::before {
  left: 0;
  top: 0;
}
.logo-slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.logo-slider .slide {
  height: 100px;
  width: 250px;
  margin: 20px;
}
.slide img {
  object-fit: contain;
}
